import React from "react";
import Slider from "react-slick";
import Image from "next/image";

import trusted_by_lucid from "../../public/trusted-by-lucid.png";
import trusted_by_cint from "../../public/trusted-by-cint.png";
import trusted_by_purespectrum from "../../public/trusted-by-purespectrum.png";
import trusted_by_pollfish from "../../public/trusted-by-pollfish.png";
import trusted_by_researchforgood from "../../public/trusted-by-researchforgood.png";
import trusted_by_dalia from "../../public/trusted-by-dalia.png";
import trusted_by_innovatemr from "../../public/trusted-by-innovatemr.png";
import trusted_by_marketcube from "../../public/trusted-by-marketcube.png";

const Partner = ({ logo_url }) => {
  return (
    <div className="border border-opacity-10 border-gray-300 rounded py-4 px-6 mx-auto md:mx-2">
      <Image src={logo_url} alt="" className="mx-auto" />
    </div>
  );
};
export default function Partners() {
  const partners = [
    {
      logo_url: trusted_by_lucid,
    },
    {
      logo_url: trusted_by_cint,
    },
    {
      logo_url: trusted_by_purespectrum,
    },
    {
      logo_url: trusted_by_pollfish,
    },
    {
      logo_url: trusted_by_researchforgood,
    },
    {
      logo_url: trusted_by_dalia,
    },
    {
      logo_url: trusted_by_innovatemr,
    },
    {
      logo_url: trusted_by_marketcube,
    },
  ];
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className=" bg-dark-pattern mt-20" id="partners">
      <div className="w-3/4 mx-auto py-16">
        <h4 className="text-3xl md:text-4xl font-semibold pb-8 pt-4 px-4 lg:px-32 text-white">
          Trusted By
        </h4>
        <div className="px-4 md:px-8 lg:px-32">
          <Slider {...sliderSettings} className="text-white">
            {partners.map((p, index) => {
              return <Partner key={index} logo_url={p.logo_url} />;
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}
