import React from "react";
import Image from "next/image";
import programmitc_sampling from "../../public/programmitc-sampling.jpg";

export default function Solutions() {
  return (
    <section className=" py-0 lg:py-10" id="solutions">
      <div className="grid grid-cols-1 mx-auto px-6 my-8 2xl:grid-cols-2 md:grid-cols-2 lg:px-0 lg:w-2/3 gap-4 lg:gap-10 3xl:gap-20">
        <div className="">
          <div className="">
            <Image
              src={programmitc_sampling}
              className="w-full"
              alt="Programmatic Sampling"
            />
          </div>
        </div>
        <div className="">
          <div className="py-0">
            <h3 className="font-bold text-2xl lg:text-3xl 2xl:text-4xl mb-5">
              Programmatic Sampling
            </h3>
            <p className=" mb-5">
              <br />
              Providing instant access to deeply profiled, high-quality data
              with just a click.
              <br />
              <br />
              With ROM’s sample API (application programming interfaces) you can
              link your company to all consumer data via your existing project
              management system or market place.
              <br />
              <br />
              This system-to-system connection provides a very convenient 24/7
              access to our 80 countries strong consumer panel portfolio for
              more accurate audience selection, more reliable data quality and
              results, faster survey fielding, and more robust project
              feasibility.
              <br />
              <br />
              This automated process reduces manual entry and human errors while
              creating a single and more consistent data source.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
