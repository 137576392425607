import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import {
  faLinkedinIn,
  faTwitter,
  faSlideshare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMobileAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../shared/Button";

const contactInfo = [
  {
    text: "+33673216641",
    icon: faMobileAlt,
  },
  {
    text: "info@researchonmobile.com",
    icon: faEnvelope,
  },
  {
    text: "149 avenue du Maine, 75014, Paris, France",
    icon: faMapMarkerAlt,
  },
];
const socialLinks = [
  {
    url: "https://www.linkedin.com/company/researchonmobile",
    icon: faLinkedinIn,
  },
  {
    url: "https://twitter.com/ResearchOnM",
    icon: faTwitter,
  },
  {
    url: "https://www.slideshare.net/RoMteam",
    icon: faSlideshare,
  },
];
const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  message: yup.string().required(),
});
export default function Contact() {
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  return (
    <section className="py-20" id="contact">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:w-2/3 mx-auto">
        <Formik
          validateOnChange={true}
          validationSchema={validationSchema}
          initialValues={{
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            message: "",
          }}
          onSubmit={(data, { resetForm }) => {
            if (sending === true) return;
            setSending(true);
            setMessage("Sending...");
            axios
              .post(`/api/contact`, data)
              .then(() => {
                setSending(false);
                setMessage("Sent successfully!");
                resetForm();
              })
              .catch(() => {
                setSending(false);
                setMessage("Error. Please try again...");
              });
          }}
        >
          <Form className=" bg-form-pattern bg-cover bg-center bg-no-repeat relative py-12 px-6 lg:px-12">
            <div className="absolute opacity-40 w-full h-full top-0 left-0 bg-black font-semibold"></div>
            <div className="z-20 relative text-white">
              <h3 className="text-4xl text-white mb-8 font-semibold">
                Contact Us
              </h3>
              <Field
                className="bg-transparent border rounded-md p-2 my-2 w-full border-gray-100 placeholder-gray-500 "
                name="firstName"
                placeholder="First Name"
              />
              <Field
                className="bg-transparent border rounded-md p-2 my-2 w-full border-gray-100 placeholder-gray-500 "
                name="lastName"
                placeholder="Last Name"
              />
              <Field
                className=" bg-transparent border rounded-md p-2 my-2 w-full border-gray-100 placeholder-gray-500"
                name="phone"
                typ="tel"
                placeholder="Phone"
              />
              <Field
                className=" bg-transparent border rounded-md p-2 my-2 w-full border-gray-100 placeholder-gray-500"
                name="email"
                type="mail"
                placeholder="Email"
              />
              <Field
                className=" bg-transparent border rounded-md p-2 my-2 w-full border-gray-100 placeholder-gray-500"
                name="message"
                as="textarea"
                placeholder="Message"
              />
              {message && <div>{message}</div>}
              {!sending && (
                <Button color="light" className="my-8">
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </Formik>
        <div className="py-12 px-6 bg-gray-200 lg:h-2/3 my-auto">
          <h3 className="text-3xl font-semibold mx-3">Contact Info</h3>
          <ul className="mx-5">
            {contactInfo.map((c, index) => {
              return (
                <li className="flex my-6" key={index}>
                  <FontAwesomeIcon
                    icon={c.icon}
                    className="hidden md:block w-6 h-6 mr-4"
                  />
                  {c.text}
                </li>
              );
            })}
          </ul>
          <ul className=" grid grid-flow-col gap-2 py-2 justify-start mx-3">
            {socialLinks.map((l, index) => (
              <li
                key={index}
                className="border rounded-full p-2 hover:text-gray-600 tansition duration-200 cursor-pointer"
              >
                <a href={l.url}>
                  <FontAwesomeIcon icon={l.icon} className="w-6" />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
