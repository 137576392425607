import React from "react";
import Image from "next/image";
import Link from "next/link";
import logo_svg from "../../public/ROMXRIWILOGO.png";

export default function Footer() {
  return (
    <footer className=" bg-dark-pattern" id="footer">
      <div className=" mx-auto">
        <div className=" bg-black grid grid-flow-col justify-between align-middle p-4 mt-12">
          <div className="grid gap-4 grid-flow-col">
            <Link href="/terms-and-conditions">
              <a className="hover:text-gray-400 text-white">
                Terms and Conditions
              </a>
            </Link>
            <Link href="/privacy-policy">
              <a className="hover:text-gray-400 text-white">Privacy Policy</a>
            </Link>
          </div>
          <div className="invisible md:visible">
            <Image src={logo_svg} alt="" className="mx-auto" />
          </div>
          <div>
            <p className="text-white">© 2022 Research on Mobile.</p>
          </div>
        </div>{" "}
      </div>
    </footer>
  );
}
