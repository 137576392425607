import React, { useState } from "react";
import Image from "next/image";
import cn from "classnames";
import logo_svg from "../../public/ROMXRIWILOGO.png";

export default function Nav() {
  const navLinks = [
    { url: "#home", text: "home" },
    { url: "#solutions", text: "programmatic sampling" },
    { url: "#numbers", text: "global coverage" },
    { url: "https://blog.researchonmobile.com/", text: "blog" },
    { url: "https://riwi.com/team_role/leadership/", text: "team" },
    { url: "#contact", text: "contact" },
  ];
  const [menu, setMenu] = useState(false);

  const burgerSvg = (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.62915 21.3679H28.2958M1.62915 1.36792H28.2958H1.62915ZM1.62915 11.3679H28.2958H1.62915Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const xCircleSvg = (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14L12 12L10 14ZM12 12L14 10L12 12ZM12 12L10 10L12 12ZM12 12L14 14L12 12ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  return (
    <>
      <nav className="bg-black py-6 px-8  flex flex-col xl:flex-row justify-between sticky top-0 z-50">
        <div className="lg:ml-64 flex justify-between">
          <a href="#" className="xl:mt-4">
            <Image src={logo_svg} alt="" />
          </a>
          <button
            className="xl:hidden w-10 h-10 block"
            onClick={() => setMenu(!menu)}
          >
            {menu ? xCircleSvg : burgerSvg}
          </button>
        </div>
        <ul
          className={cn(
            "xl:grid grid-flow-row xl:grid-flow-col mt-4 xl:mr-64",
            menu ? "" : "hidden"
          )}
        >
          {navLinks.map((li) => {
            return (
              <li
                key={li.text}
                onClick={() => setMenu(!menu)}
                className="px-4 py-4 xl:py-2 rounded-md text-gray-200 hover:text-white hover:bg-gray-900 cursor-pointer"
              >
                <a href={li.url} className="capitalize">
                  {li.text}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}
