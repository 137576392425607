import React from "react";
import cn from "classnames";

export default function Button({ children, color, className }) {
  return (
    <button
      className={cn(
        className,
        color === "light"
          ? "hover:text-black hover:bg-yellow-500 bg-yellow-300 text-black border-black"
          : "hover:text-white hover:bg-black text-black border-black",
        "font-semibold bg-transparent py-2 px-4 border rounded-full text-lg lg:text-xl z-10 uppercase transition-all duration-200"
      )}
    >
      {children}
    </button>
  );
}
