import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import axios from "axios";
const Map = dynamic(
  () => import("../shared/Map"), // replace '@components/map' with your component's location
  { ssr: false } // This line is important. It's what prevents server-side render
);

const Numbers = () => {
  const [number, setNumber] = useState(0);
  useEffect(() => {
    axios.get("api/active-users").then((res) => {
      setNumber(numberWithCommas(res.data.data.total));
    });
  }, []);

  const markers = [
    {
      cords: ["52.493024", "13.336632"],
    },
    {
      cords: ["52.455740", "13.322559"],
    },
  ];
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <section id="numbers" className=" bg-map-pattern">
      <div className=" lg:w-2/3 mx-auto relative">
        <h3 className="relative font-semibold top-12 lg:top-32 text-4xl text-center">
          Global Coverage
        </h3>
        <Map
          markers={markers}
          className=" relative h-64 top-12 lg:top-40 md:h-96 lg:h-140 w-full z-30"
        />
      </div>
      <div className="pb-12 pt-12 lg:pt-48 my-10 bg-dark-pattern text-white text-center mx-auto">
        <h3 className="uppercase">RESPONDENTS</h3>
        <h2 className="text-3xl my-6">Number of respondents in 2020</h2>
        <div className="mx-auto py-2 px-2 md:px-6 text-2xl tracking-widest border-gray-800">
          <p className="border border-white rounded-md  w-48 lg:w-96 border-opacity-50 md:px-6 py-2 md:py-4 mx-auto">
            {number}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
