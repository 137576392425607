import React from "react";
import Button from "../shared/Button";

export default function Hero() {
  return (
    <section id="home">
      <div className="pb-36 pt-24 flex items-center flex-col bg-hero-pattern relative bg-no-repeat bg-cover bg-center">
        <h3 className="text-white font-bold text-2xl lg:text-3xl 2xl:text-4xl mb-5">
          Global Profiled Respondents
        </h3>

        <h2 className="text-white font-bold mb-5 text-xl text-center">
          Get fast & cost-effective access to millions of quality fresh
          respondents in 80+ countries
        </h2>

        <Button color="light">
          <a href="#contact">Get in touch</a>
        </Button>
      </div>
    </section>
  );
}
