import React from "react";
import Slider from "react-slick";
import Image from "next/image";
import screenshot_1 from "../../public/screenshot_1.png";
import screenshot_2 from "../../public/screenshot_2.png";
import screenshot_3 from "../../public/screenshot_3.png";
import screenshot_4 from "../../public/screenshot_4.png";
import screenshot_5 from "../../public/screenshot_5.png";

const Screen = ({ img }) => {
  return (
    <div className="mx-atuo md:mx-0">
      <div className="lg:mx-8">
        <Image src={img} alt="" className="h-120 w-60 mx-auto" />
      </div>
    </div>
  );
};

const AppScreen = () => {
  const screens = [
    {
      link: screenshot_1,
    },
    {
      link: screenshot_2,
    },
    {
      link: screenshot_3,
    },
    {
      link: screenshot_4,
    },
    {
      link: screenshot_5,
    },
  ];
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="py-10" id="appscreen">
      <div className="px-6 lg:px-0 lg:w-2/3 mx-auto">
        <h3 className="mb-12 text-4xl text-center">App Screen</h3>
        <Slider {...sliderSettings}>
          {screens.map((s, index) => (
            <Screen key={index} img={s.link} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default AppScreen;
